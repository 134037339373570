// Dependency
import * as React from "react"
import {graphql} from "gatsby";

// Components
import Layout from "../../components/layouts";
import StickyGrid from "../../components/sticky-grid/sticky-grid";

// Styles

// Assets
import hero from "../../images/hero/integritetsskydds.jpg";


// GraphQL to get Dato data
export const query = graphql`
query ( $locale: String! = "en"){
  datoCmsPrivacyPage (locale: {eq: $locale}){
    title
    slug
    content
  }
}
`

// Template
const PrivacyPage = (props) => {

    const pageData = props.data.datoCmsPrivacyPage;

    const metaData = {
        name: pageData.title,
        image:{
            src: hero,
        }
    }

    return (
        <Layout data={metaData} path={props.location} locale={props.pageContext.locale}>
            <div className="cd-pp-page">
                <StickyGrid
                    title={'Gro Advokatbyrå'}
                    subTitle={pageData.title}
                    pageContent={pageData.content}
                />
            </div>
        </Layout>
    )
}

export default PrivacyPage
